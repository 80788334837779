import moment from 'moment';
import { useCartState } from '../../contexts/cart';
import { useFilterState } from '../../components/cap';
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import { usePricing } from '../usePricing';
import useAnalyticsHelper from './useAnalyticsHelper';
import useAnalytics from './useAnalytics';
import { getRentalDuration, getUniqueCategories } from '../../components/global/utils/commonUtils';
import { isValidString } from '../../components/global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';
import { EVENT_P2P_NAMES_CONFIG } from '../../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { TILE_STATES } from '../../components/cap/constants';
import { GENERAL_EQUIPMENT_AND_TOOLS } from '../../components/location/constants';
import { SET_CART_ITEM_STATUS_CHANGED_REASON } from '../../aem-core-components/actions/constants';

const useCartAnalytics = () => {
    const [{ cart, cartItemsAvailabilityChangedReason, homeAssetsRates, orderSummaryDetails }, dispatch] =
        useCartState();
    const [{ startDate, endDate, viewCart }] = useFilterState();
    const { getOverrideRatesPc } = usePricing();
    const { sendEventsForAddToCart, sendEventsForCtaClicked, sendEventsForEcommerceAction } = useAnalyticsContext();
    const [
        {
            createInventoryItemObject,
            getEcommerceDataWithoutItems,
            getItemAvailabilityTileStatus,
            getItemsForAnalytics,
            getListItemDetails
        }
    ] = useAnalyticsHelper();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();

    const payloadEcommerceInventoryAddToCartAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const item = createInventoryItemObject(data, data?.index);
            return item;
        });
        let ecommerce = {
            items,
            ...getEcommerceDataWithoutItems()
        };
        return { ecommerce };
    };

    const payloadEcommerceInventoryListingAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const { category, category_path, index } = data || {};
            const item = createInventoryItemObject(data, index);
            const overridePc = getOverrideRatesPc();
            item.location_id = overridePc ? overridePc : homeAssetsRates[data?.catclass]?.ownedPc;
            item.item_category =
                category?.[0] || category_path?.[0] || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            item.item_category2 =
                category?.[1] || category_path?.[1] || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            item.item_category3 =
                category?.[2] || category_path?.[2] || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            return item;
        });
        return { items };
    };

    const payloadEcommerceInventoryViewedAnalytics = alternateInventory => {
        let items;
        items = alternateInventory?.map(data => {
            const item = createInventoryItemObject(data, data?.index);
            return item;
        });

        return { items };
    };

    const payloadP2PAddToCartActionAnalytics = (id, name) => {
        const { estimatedSubtotal, deliveryPickUpCharges } = orderSummaryDetails;
        const deliveryMethod = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
            ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
            : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY;
        let itemsList = {
            item_list_id: VARIABLE_CONFIG.P2P.ITEM_FAVORITES,
            item_list_name: VARIABLE_CONFIG.P2P.ITEM_FAVORITES_LIST
        };

        let addToCartPayload = {
            ecommerce: {
                currency:
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                        ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                        : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                value: estimatedSubtotal,
                delivery_method: deliveryMethod,
                delivery_fee: deliveryMethod === VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY ? deliveryPickUpCharges : 0,
                start_date: moment(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).format(
                    EVENT_P2P_NAMES_CONFIG.P2P_DATE_FORMAT
                ),
                end_date: moment(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE)).format(
                    EVENT_P2P_NAMES_CONFIG.P2P_DATE_FORMAT
                ),
                items: getItemsForAnalytics(itemsList)
            }
        };
        return { ...addToCartPayload };
    };

    const sendAddToCartAnalytics = (product, isSimilarItems = false, rates = []) => {
        const productArr = payloadEcommerceInventoryAddToCartAnalytics([product]);
        const { currency } = productArr?.ecommerce || {};
        const itemsArr = payloadEcommerceInventoryListingAnalytics([product]);
        const { item_id, item_category, item_category2, item_category3, quantity } = itemsArr?.items[0] || {};
        const { name, producttitle, index, listName, categoryname, parentCategory, subcategoryname } = product || {};
        const price =
            rates[0]?.rates?.suggestedRates?.daily ||
            (isNaN(homeAssetsRates[item_id]?.daily)
                ? VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
                : homeAssetsRates[item_id]?.daily);
        let items = [];
        const { list_id, list_name } = getListItemDetails(listName) || {};
        const item = {
            item_id,
            item_name: name?.trim() || producttitle?.trim(),
            item_list_id: list_id,
            item_list_name: list_name,
            categories: getUniqueCategories([
                item_category || categoryname,
                item_category2 || parentCategory,
                item_category3 || subcategoryname
            ]),
            price: price,
            quantity
        };
        if (isSimilarItems || listName) {
            item.position = index + 1;
        } else {
            item.index = 0;
        }
        items.push(item);
        sendEventsForAddToCart(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ADD_TO_CART, currency, price, items);
    };

    const payloadEcommerceCartItemAnalytics = (item, isValueRequired = false) => {
        let assetsRates = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)
            ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES))
            : {};
        const { product, prices, quantity } = item || {};
        const { daily, weekly, monthly } = assetsRates?.[product?.sku] || {};
        try {
            let payload = {
                currency:
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                        ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                        : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                items: [
                    {
                        item_id: product?.sku,
                        item_name: product?.name,
                        item_list_id: VARIABLE_CONFIG.CART.LIST_ID,
                        item_list_name: VARIABLE_CONFIG.CART.LIST_NAME,
                        categories: product?.categories,
                        price: prices?.price?.value,
                        quantity: quantity,
                        index: cart?.items?.findIndex(cartItem => cartItem.product?.sku === product?.sku),
                        daily: daily || prices?.price?.value || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                        weekly: weekly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                        monthly: monthly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                        availability: getItemAvailabilityTileStatus(item?.status)
                    }
                ]
            };
            if (isValueRequired) return { ...payload, value: prices?.row_total?.value };
            return { ...payload };
        } catch (error) {
            logError(error, false, 'payloadEcommerceCartItemAnalytics');
            return {};
        }
    };

    const getCartItemsForAnalytics = (availableItem, conditionalItems, unavailableItems) => {
        try {
            let itemsCart = [];
            let assetsRates = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES)) || {};
            availableItem.map(item => {
                item['status'] = TILE_STATES.AVAILABLE;
            });
            unavailableItems.map(item => {
                item['status'] = TILE_STATES.UNAVAILABLE;
            });
            const cartItems = [...(availableItem || []), ...(conditionalItems || []), ...(unavailableItems || [])];
            const availabilityReasonChanged =
                cartItemsAvailabilityChangedReason ||
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS_AVAILABILITY_CHANGED_REASON) ||
                '';
            cartItems?.forEach((item, index) => {
                const { product, prices, quantity } = item || {};
                const { daily, weekly, monthly } = assetsRates?.[product?.sku] || {};
                const items = {
                    item_id: product?.sku,
                    item_name: product?.name,
                    categories: product?.category_path,
                    price: prices?.price?.value,
                    quantity: quantity,
                    index: index + 1,
                    daily: daily || prices?.price?.value || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                    weekly: weekly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                    monthly: monthly || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                    item_list_id: VARIABLE_CONFIG.CART.LIST_ID,
                    item_list_name: VARIABLE_CONFIG.CART.LIST_NAME,
                    availability: getItemAvailabilityTileStatus(item?.status),
                    availability_changed: !!availabilityReasonChanged,
                    reason_changed: availabilityReasonChanged
                };
                itemsCart.push(items);
            });
            return itemsCart;
        } catch (error) {
            logError(error, false, 'getCartItemsForAnalytics');
        }
    };

    const getCartEcommerceData = (startDate, endDate, availableItem, conditionalItems, unavailableItems) => {
        try {
            return {
                ...getEcommerceDataWithoutItems(startDate, endDate),
                items: getCartItemsForAnalytics(availableItem, conditionalItems, unavailableItems)
            };
        } catch (error) {
            logError(error, false, 'getCartEcommerceData');
            return {};
        }
    };

    const payloadViewCartActionAnalytics = (availableItem, conditionalItems, unavailableItems, fulfillmentRatio) => {
        try {
            const selectedStoreDetails = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS) || '{}'
            );
            const { pc, specialties, fulfillmentPercent, distance, distanceFromJobSite } = selectedStoreDetails || {};
            const cartObj = {
                fulfillment_pc: pc,
                pc_type: specialties?.[0] || GENERAL_EQUIPMENT_AND_TOOLS,
                fulfillment_ratio: fulfillmentRatio || fulfillmentPercent,
                nearest_pc: distance ?? distanceFromJobSite,
                unavailable_items: unavailableItems?.length > 0 || conditionalItems?.length > 0
            };
            const ecommerce =
                getCartEcommerceData(startDate, endDate, availableItem, conditionalItems, unavailableItems) || {};
            return { ...cartObj, ...ecommerce };
        } catch (error) {
            logError(error, false, 'payloadViewCartActionAnalytics');
            return {};
        }
    };

    const getProductPayload = (quantity, item) => {
        const { product, prices } = item || {};
        const { sku, name, productcategoryname, parent_category_name, category_name, category_path, position } =
            product || {};
        const { value } = prices?.row_total || {};
        return [
            {
                id: sku,
                name: name,
                price: parseFloat(value)?.toFixed(2),
                categories: category_path || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                category: productcategoryname || parent_category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                subcategory: category_name || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension38: getRentalDuration(startDate, endDate) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                dimension31: value,
                dimension32: value * 7,
                dimension33: value * 31,
                sku: sku,
                dimension41: -1,
                list: VARIABLE_CONFIG.ECOMMERCE_PAGE.CART,
                position: parseInt(position) || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                quantity: quantity
            }
        ];
    };

    /* Trigger event when cart page is loaded or cart items are updated */
    const triggerCartViewedEvent = (availableItem, conditionalItems, unavailableItems, sortedChronosPcs) => {
        try {
            let fulfillmentRatio;
            if (sortedChronosPcs?.length > 0) {
                let cartPc = viewCart?.pc;
                fulfillmentRatio = sortedChronosPcs?.find(
                    sortedChronosPc => sortedChronosPc?.pc === cartPc
                )?.fulfillmentPercent;
            }
            if (availableItem?.length || conditionalItems?.length || unavailableItems?.length) {
                sendEventsForEcommerceAction(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CART_VIEWED,
                    payloadViewCartActionAnalytics(availableItem, conditionalItems, unavailableItems, fulfillmentRatio)
                );
                dispatch({ type: SET_CART_ITEM_STATUS_CHANGED_REASON, cartItemsAvailabilityChangedReason: '' });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CART_ITEMS_AVAILABILITY_CHANGED_REASON, '');
            }
        } catch (error) {
            logError(error, false, 'triggerCartViewedEvent');
        }
    };

    const triggerCheckoutStartEvent = () => {
        try {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STARTED,
                payloadEcommerceActionAnalytics()
            );
        } catch (error) {
            logError(error, false, 'triggerCheckoutStartEvent');
        }
    };

    const triggerEventForViewDetailsCta = anchorTagName => {
        try {
            sendEventsForCtaClicked(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                anchorTagName,
                VARIABLE_CONFIG.TYPE.BUTTON,
                VARIABLE_CONFIG.EVENT_LINK_LOCATION.BODY,
                VARIABLE_CONFIG.EVENT_LINK_DESTINATION.NOT_AVAILABLE
            );
        } catch (error) {
            logError(error, false, 'triggerEventForViewDetailsCta');
        }
    };

    return [
        {
            payloadEcommerceCartItemAnalytics,
            payloadEcommerceInventoryAddToCartAnalytics,
            payloadEcommerceInventoryViewedAnalytics,
            payloadP2PAddToCartActionAnalytics,
            payloadViewCartActionAnalytics,
            sendAddToCartAnalytics,
            getProductPayload,
            triggerCartViewedEvent,
            triggerCheckoutStartEvent,
            triggerEventForViewDetailsCta
        }
    ];
};

export default useCartAnalytics;
