import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import CART_DETAILS_QUERY from '../../../aem-core-components/queries/query_cart_details.graphql';
import { getCartDetails } from '../../../aem-core-components/actions/cart';
import { useFilterState } from '../filterContext';
import { useCartState } from '../../../contexts/cart';
import { usePdpState } from '../../../contexts/pdp/pdpContext';
import { useCheckUser, useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAtp } from './useATP';
import useComputeLocation from '../../../hooks/useComputeLocation';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useAwaitQuery } from '../../../aem-core-components/utils/hooks';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import {
    geoCodeByLatLong,
    getLocationsDetailsByPlaceId,
    getlatLongByAddress
} from '../../global/modules/location-autocomplete/api/getLocations';
import { formatNearbyPC, formatStoreDataToObject, isCCPage, isTier2Radius } from '../../global/utils/commonUtils';
import { isValidString, logError } from '../../global/utils/logger';
import { getStoreLocations } from '../../location/API/getStoreLocations';
import { getDateDiffInHrs } from '../utils/atputils';
import { getExcludedPCfromList, createFullAddress } from '../utils/capHelper';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_ACCOUNT_TYPE, USER_TYPE } from '../../../constants/userDetailsConstants';
import {
    EDIT_VIEW_DETAILS,
    EDIT_VIEW_OPEN,
    FULFILLMENT_TYPE,
    SET_BSR_LIST,
    SET_IS_CAP_DETAILS_UPDATING,
    SET_PICKUP_STORES,
    TILE_STATES
} from '../constants';
import { usePageType } from '../../../hooks/usePageType';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useMedia from '../../../hooks/useMedia';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import { RESET_CLICKS, SET_RATES_LOADING_FLAG, SET_UNAVAILABLE_CART_ITEMS_PER_PC } from '../../../aem-core-components/actions/constants';
import { AUTHORITY_TYPE } from '../../global/constants';

export const useCapUtils = () => {
    const capIntl = useIntl();
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);
    const authorityType = useCheckAuthorityType();
    const [
        {
            viewCart,
            projectDetails,
            startDate,
            endDate,
            selectedStoreDetails: selectedPickupStoreContext,
            bsrList,
            searchRadiusForLocationCall,
            pickupStores
        },
        dispatch
    ] = useFilterState();
    const [{ userProfile }] = useUserContext();
    const { handleATPCart, handleATP } = useAtp();
    const userType = useCheckUser();
    const [{ cartId, cart, userAccount }, cartDispatch] = useCartState();
    const [, pdpDispatch] = usePdpState();
    const pageType = usePageType();
    const { getBSRList, cityAndZipWithoutStreetValidation, extractAddressComponents, getZipFromLatLong } =
        useComputeLocation();
    const { isRentalDetailsAvailable, fetchLocationCoordinates } = useCheckLocationEmpty();
    const { rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const { json: capHeaderJson = null } = ENV_CONFIG.CAP_HEADER || {};
    const { tier1SearchRadius, tier2SearchRadius } = searchRadiusForLocationCall || {};
    const isAtpDisabled = rollbackToCi || false;
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const isSelectedAccountIsNonCorpAccount = userProfile?.isSelectedAccountIsNonCorpAccount;
    const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const mediaType = useMedia();
    const storageCartQuantity = isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY));
    const getJobsiteWithLatLong = async jobsite => {
        try {
            let projectLat = jobsite?.selectedProjectLatititude;
            let projectLong = jobsite?.selectedProjectLongitude;
            if (!projectLat || !projectLong) {
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const fullAddress = createFullAddress(jobsite, true);
                if (fullAddress) {
                    const response = await getlatLongByAddress(companyID, fullAddress);
                    if (response?.data?.results?.length > 0) {
                        projectLat = response?.data?.results[0]?.geometry?.location?.lat;
                        projectLong = response?.data?.results[0]?.geometry?.location?.lng;
                    }
                }
            }
            return { ...jobsite, selectedProjectLatititude: projectLat, selectedProjectLongitude: projectLong };
        } catch (error) {
            logError(error, false, 'getJobsiteWithLatLong', [jobsite]);
        }
    };

    const distanceText = companyId => {
        let companyID = companyId ? companyId : parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID));
        if (companyID === 2) {
            return capIntl.formatMessage({ id: 'cap:location-km-text' });
        }
        return capIntl.formatMessage({ id: 'cap:location-miles-text' });
    };

    const updateBsrPricingPCs = async payload => {
        const {
            localLat,
            localLong,
            localStartDate = startDate,
            localIsInStorePickup = viewCart?.isInStorePickup,
            companyID,
            isSourceCallRequired = false
        } = payload || {};
        try {
            const isCreditProjectOrCashLocation =
                localLat || projectDetails?.selectedProjectLatititude || viewCart?.lat;
            if (isCCPage()) {
                // to clear always on ccpage for fresh call
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            }
            const dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
            if (!localIsInStorePickup && dateDiff <= 24 && isCreditProjectOrCashLocation) {
                let bsrDataForStorage = [];
                if (!isSourceCallRequired) {
                    bsrDataForStorage =
                        JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST) || '[]') || [];
                }
                if (!bsrDataForStorage || !bsrDataForStorage?.length) {
                    const { data } = await getBSRList({
                        lat: localLat,
                        long: localLong,
                        companyID,
                        updateInContext: !isSourceCallRequired
                    });
                    // Create an array that combines branchCompany and branchNumber
                    if (data?.length > 0) {
                        const combinedArray = data?.map(branch =>
                            formatNearbyPC(branch?.branchNumber, branch?.branchCompany)
                        );
                        if (!isSourceCallRequired) {
                            sessionStorage.setItem(
                                STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST,
                                JSON.stringify(combinedArray)
                            );
                        }
                        return {
                            bsrDataForStorage: combinedArray,
                            bsrDaraForContext: data
                        };
                    } else {
                        if (!isSourceCallRequired) {
                            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
                        }
                        return {
                            bsrDataForStorage: [],
                            bsrDaraForContext: []
                        };
                    }
                }
                return {
                    bsrDataForStorage,
                    bsrDaraForContext: bsrList
                };
            }

            return {
                bsrDataForStorage: [],
                bsrDaraForContext: []
            };
        } catch (error) {
            logError(error, false, 'updateBsrPricingPCs');
        }
    };

    const fetchLocalLocationCoordinates = async (placeId, jobsiteDetails, isJobsiteSelected) => {
        try {
            let lat = '';
            let long = '';
            let addressDetails = {};
            let companyId = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
            if (isValidString(placeId) && !isJobsiteSelected) {
                const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(placeId);
                if (responseFromPlaceDetails?.error || isObjectEmpty(responseFromPlaceDetails?.data?.result)) {
                    return;
                }

                let { jobSiteCity, jobSiteState, jobSiteZip, country } = extractAddressComponents(
                    responseFromPlaceDetails?.data?.result,
                    false
                );
                companyId = country;
                lat = responseFromPlaceDetails?.data?.result?.geometry?.location?.lat || '';
                long = responseFromPlaceDetails?.data?.result?.geometry?.location?.lng || '';
                if (!cityAndZipWithoutStreetValidation(responseFromPlaceDetails?.data?.result, companyId)) {
                    return { companyId, lat, long };
                }

                if (!jobSiteZip) {
                    let latlng = `${lat},${long}`;
                    let geocoderesponse = await geoCodeByLatLong(latlng);
                    jobSiteZip = getZipFromLatLong(geocoderesponse?.data?.results[0]);
                }

                addressDetails = {
                    jobSiteAddr2: '',
                    jobSiteCity,
                    jobSiteState,
                    jobSiteZip
                };
            } else {
                lat = jobsiteDetails?.selectedProjectLatititude;
                long = jobsiteDetails?.selectedProjectLongitude;
            }
            return { lat, long, companyId, addressDetails };
        } catch (error) {
            logError(error, false, 'fetchLocalLocationCoordinates');
        }
    };

    const showToggleButton = () => {
        if (
            isRentalDetailsAvailable() &&
            !isValidString(isAtpDisabled) &&
            !isValidString(isTier2Radius()) &&
            !overridePC?.pc
        ) {
            return true;
        } else {
            return false;
        }
    };

    const getCapUserDetails = () => {
        try {
            const capJson = JSON.parse(capHeaderJson) || {};
            if (userType === USER_TYPE.GUEST || userType === USER_TYPE.CASH) {
                return capJson[userType];
            } else if (authorityType === AUTHORITY_TYPE.P2P || authorityType === AUTHORITY_TYPE.PUNCHOUT) {
                return isSelectedAccountIsNonCorpAccount
                    ? capJson[USER_ACCOUNT_TYPE.P2PNONCORP]
                    : capJson[USER_ACCOUNT_TYPE.P2PCORP];
            } else if (overridePC?.pc) {
                return capJson[USER_ACCOUNT_TYPE.OVERRIDEACCOUNT];
            } else {
                return capJson[USER_TYPE.CREDIT];
            }
        } catch (error) {
            logError(error, false, 'getCapUserDetails');
        }
    };

    const getSelectedStoreLabel = selectedPickupStore => {
        if (isObjectEmpty(selectedPickupStore)) {
            return capIntl.formatMessage({ id: 'cap:no-store-nearby' });
        } else {
            return (
                <>
                    {selectedPickupStore?.city} {selectedPickupStore?.state} -{' '}
                    {capIntl.formatMessage({ id: 'cap:store-details-branch' })}
                    {selectedPickupStore?.pc} (
                    {Number(selectedPickupStore?.distance ?? selectedPickupStore?.drivingDistanceFromJobsite).toFixed(
                        2
                    )}{' '}
                    {distanceText(selectedPickupStore?.companyId)})
                </>
            );
        }
    };

    const getLocalEstimatesLocationDetails = ({ isJobsiteSelected, jobsiteWithLatLong, locationDetails }) => {
        try {
            if (isJobsiteSelected) {
                return {
                    lat: jobsiteWithLatLong?.selectedProjectLatititude,
                    long: jobsiteWithLatLong?.selectedProjectLongitude
                };
            } else {
                return {
                    lat: locationDetails?.lat,
                    long: locationDetails?.long
                };
            }
        } catch (error) {
            logError(error, false, 'getLocalEstimatesLocationDetails', [
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            ]);
        }
    };

    const getPayloadForCompareEstimates = async ({
        localEstimatesLocationDetails,
        filterStartDate,
        filterEndDate,
        companyID,
        fulfillmentValue,
        isSourceCallRequired
    }) => {
        try {
            let bsrData = {};
            let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            if (!overridePC?.pc) {
                if (filterEndDate && filterStartDate) {
                    if (isSourceCallRequired) {
                        bsrData = await updateBsrPricingPCs({
                            localLat: localEstimatesLocationDetails?.lat,
                            localLong: localEstimatesLocationDetails?.long,
                            localStartDate: filterStartDate,
                            localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                            companyID,
                            isSourceCallRequired
                        });
                    }
                }
                return bsrData;
            }
        } catch (error) {
            logError(error, false, 'getPayloadForCompareEstimates', [localEstimatesLocationDetails]);
        }
    };

    const createUpdatedCartData = (
        filterStartDate,
        fulfillmentValue,
        selectedPickupStore,
        locationDetails,
        bsrData,
        companyID,
        localIsTier2Radius,
        localNearbyPcs,
        estimatesResponse
    ) => {
        try {
            const cartUpdatedData = [];
            const unavailableCartItems = [];
            estimatesResponse?.items?.forEach(data => {
                let isCICheckRequired = filterStartDate ? isValidString(isAtpDisabled) : true;
                let payload = {
                    catsku: data?.product?.sku,
                    inventoryDataObj: data?.product?.ec_pc_inventory,
                    localStartDate: filterStartDate,
                    localIsInStorePickup: fulfillmentValue === FULFILLMENT_TYPE.PICKUP,
                    locationPCData: selectedPickupStore,
                    isLocalLocationEmpty: !locationDetails?.lat,
                    localBsrData: bsrData,
                    localCompanyID: companyID,
                    localIsTier2Radius,
                    localNearbyPcs,
                    pcAvailability: data?.pc_availability
                };
                let tileStatus = isCICheckRequired ? handleATPCart(payload) : handleATP(payload);
                // if (filterStartDate && !isValidString(isAtpDisabled)) {
                //     if (tileStatus === TILE_STATES.UNAVAILABLE) {
                //         cartUpdatedData.push({ ...data, tileStatus });
                //     }
                // } else if (tileStatus === TILE_STATES.UNAVAILABLE) {
                //     cartUpdatedData.push({ ...data, tileStatus });
                // }
                if (tileStatus === TILE_STATES.UNAVAILABLE) {
                    cartUpdatedData.push({ ...data, tileStatus });
                }
                if (tileStatus !== TILE_STATES.AVAILABLE && tileStatus !== TILE_STATES.AVAILABLE_WITH_WARNING) {
                    unavailableCartItems.push({ ...data, tileStatus })
                }
            });
            return { cartUpdatedData, unavailableCartItems };
        } catch (error) {
            logError(error, false, 'createUpdatedCartData', [estimatesResponse]);
            return [];
        }
    };

    const getBSRData = async (cartDetailsPayload, locationDetails) => {
        try {
            const NewAddress =
                JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
            const isCreditProjectAddress = userType == USER_TYPE.CREDIT && !NewAddress;
            const {
                isJobsiteSelected = isCreditProjectAddress ? true : false,
                jobsiteDetails = projectDetails,
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                filterStartDate = startDate,
                filterEndDate = endDate,
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY,
                isSourceCallRequired = true,
            } = cartDetailsPayload;

            let jobsiteWithLatLong = null;
            if (isJobsiteSelected) {
                jobsiteWithLatLong = await getJobsiteWithLatLong(jobsiteDetails);
            }
            const localEstimatesLocationDetails = getLocalEstimatesLocationDetails({
                isJobsiteSelected,
                jobsiteWithLatLong,
                locationDetails
            });
            const bsrData = await getPayloadForCompareEstimates({
                localEstimatesLocationDetails,
                filterStartDate,
                filterEndDate,
                companyID,
                fulfillmentValue,
                isSourceCallRequired
            });
            const { bsrDataForStorage = [], bsrDaraForContext = [] } = bsrData || {};

            return {
                bsrDataForStorage,
                bsrDaraForContext
            };
        } catch (error) {
            logError(error, false, 'getBSRData');
            return {};
        }
    }

    const getCartUpdatedDetails = async cartDetailsPayload => {
        /* To take the values default values from context if details are not passed */
        try {
            let estimatesResponse = cart;
            const { localLat, localLong } = fetchLocationCoordinates();
            const {
                locationDetails = { lat: localLat, long: localLong },
                localNearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                selectedPickupStore = selectedPickupStoreContext,
                filterStartDate = startDate,
                fulfillmentValue = viewCart?.isInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY,
                localIsTier2Radius = isValidString(isTier2Radius())
            } = cartDetailsPayload;

            const { bsrDataForStorage, bsrDaraForContext } = getBSRData(cartDetailsPayload, locationDetails);
            const { cartUpdatedData } = createUpdatedCartData(
                filterStartDate,
                fulfillmentValue,
                selectedPickupStore,
                locationDetails,
                bsrDataForStorage,
                companyID,
                localIsTier2Radius,
                localNearbyPcs,
                estimatesResponse
            );
            return {
                cartDetails: cartUpdatedData,
                bsrDataForStorage,
                bsrDaraForContext
            };
        } catch (error) {
            logError(error, false, 'getCartUpdatedDetails', [cartDetailsPayload]);
            return {};
        }
    };

    const getCartUnavailableItems = async (cartDetailsPayload, isContextUpdateRequired = false) => {
        try {
            const { localLat, localLong } = fetchLocationCoordinates();
            const {
                locationDetails = { lat: localLat, long: localLong },
                localNearbyPcs = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST) || '[]'),
                companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                pickupStoresData = [],
                filterStartDate = startDate,
                localIsTier2Radius = isValidString(isTier2Radius()),
                estimatesResponse = cart
            } = cartDetailsPayload;

            const { bsrDataForStorage } = getBSRData(cartDetailsPayload, locationDetails);
            const unavailableCartItemsList = {};
            if (estimatesResponse?.items?.length > 0) {
                pickupStoresData?.forEach((store) => {
                    const { unavailableCartItems } = createUpdatedCartData(
                        filterStartDate,
                        FULFILLMENT_TYPE.PICKUP,
                        store,
                        locationDetails,
                        bsrDataForStorage,
                        companyID,
                        localIsTier2Radius,
                        localNearbyPcs,
                        estimatesResponse
                    );

                    unavailableCartItemsList[store.pc] = unavailableCartItems?.map(item => ({
                        sku: item?.product?.sku,
                        name: item?.product?.name
                    })) || []

                });
            }
            if (isContextUpdateRequired) {
                cartDispatch({
                    type: SET_UNAVAILABLE_CART_ITEMS_PER_PC,
                    unavailableCartItemsPerPc: unavailableCartItemsList
                });
            }
            return unavailableCartItemsList;
        } catch (error) {
            logError(error, false, 'getCartUnavailableItems');
            return {};
        }
    };


    const getStoresData = async (
        lat = '',
        long = '',
        companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
        tier1Radius = tier1SearchRadius
    ) => {
        try {
            if (lat && long) {
                const { data } = await getStoreLocations(lat, long, tier1Radius, companyID);
                const pcList = data?.data?.pcList;
                /* data for Tier1 radius */
                if (pcList?.length > 0) {
                    const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcList);
                    return {
                        pricingPcs,
                        pickupStorePcs,
                        distance: tier1Radius
                    };
                } else {
                    const { data } = await getStoreLocations(lat, long, tier2SearchRadius, companyID);
                    const pcListArr = data?.data?.pcList;
                    /* data for Tier2 radius */
                    if (pcListArr?.length > 0) {
                        const { pricingPcs, pickupStorePcs } = getExcludedPCfromList(pcListArr);
                        return {
                            pricingPcs,
                            pickupStorePcs,
                            distance: tier2SearchRadius
                        };
                    }
                }
            }
            return {
                pricingPcs: [],
                pickupStorePcs: [],
                distance: tier1SearchRadius
            };
        } catch (error) {
            logError(error, false, 'getStoresData');
            return {
                pricingPcs: [],
                pickupStorePcs: [],
                distance: tier1SearchRadius
            };
        }
    };
    const isCapHeroComponent = () => {
        const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
        if (isHomePage) {
            return true;
        }
        return false;
    };
    const isLocationPageHeroComponent = () => {
        const isLocationPage = pageType === VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
        if (isLocationPage && mediaType == MEDIA_TYPE.DESKTOP) {
            return true;
        }
        return false;
    };

    const startRenting = () => {
        dispatch({ type: EDIT_VIEW_OPEN });
    };

    const onEditViewSourceMatch = () => {
        dispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
    };

    const onRangePickerToggle = isOpen => {
        if (!isOpen) {
            dispatch({ type: EDIT_VIEW_OPEN });
        }
    };

    const handleResetClick = () => {
        cartDispatch({ type: RESET_CLICKS });
        const scrollY = window?.scrollY;
        window.scrollTo(0, scrollY); // to prevent unneccessary scroll after modal close
    };

    const isDatesChanged = (filterStartDate, filterEndDate) => {
        return startDate !== filterStartDate || endDate !== filterEndDate;
    };

    const isStoreDetailsUpdated = isStoreLocationFetching => {
        if (isTier2Radius() || !isStoreLocationFetching) {
            return true;
        }
        return false;
    };

    const startUpdateAndRatesLoading = () => {
        try {
            dispatch({ type: SET_IS_CAP_DETAILS_UPDATING, isCapDetailsUpdating: true });
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: true
            });
            if (pageType === VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL) {
                pdpDispatch({
                    type: SET_RATES_LOADING_FLAG,
                    isRatesLoading: true
                });
            }
        } catch (error) {
            cartDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            pdpDispatch({
                type: SET_RATES_LOADING_FLAG,
                isRatesLoading: false
            });
            logError(error, false, 'startUpdateAndRatesLoading');
        }
    };

    const getCapLocationLabel = rentalLocationLabel => {
        if (isP2PUser) {
            if (!userAccount?.accountNumber) {
                return capIntl.formatMessage({ id: 'cap:choose-account-and-jobsite' });
            } else {
                return capIntl.formatMessage({ id: 'cap:choose-a-jobsite' });
            }
        } else {
            return rentalLocationLabel;
        }
    };

    const updateBSRInStorageAndContext = (bsrData = {}) => {
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST,
            JSON.stringify(bsrData?.bsrDataForStorage || [])
        );
        dispatch({ type: SET_BSR_LIST, bsrList: bsrData?.bsrDaraForContext || [] });
    };

    const isCartQuantityDiffFromStorage = () => {
        const itemsQuantity = cart?.items?.reduce((totalQuantity, item) => totalQuantity + item?.quantity, 0);
        return storageCartQuantity && storageCartQuantity != itemsQuantity;
    }

    const fetchAndUpdatePickupStores = async tier1Radius => {
        //function is used to fetch pickup stores and cart details, then call getCartUnavailableItems with these details to retrieve the Map of unavailable items.
        try {
            const companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
            const { localLat, localLong } = fetchLocationCoordinates();
            const cartDetailsPromise = cartId && isCartQuantityDiffFromStorage() ?
                getCartDetails({
                    cartDetailsQuery,
                    dispatch: cartDispatch,
                    cartId
                }) : Promise.resolve(null);

            const storesDataPromise = getStoresData(localLat, localLong, companyID, tier1Radius);

            // Wait for both promises to resolve
            const [cartResult, storesData] = await Promise.all([cartDetailsPromise, storesDataPromise]);
            const { pickupStorePcs } = storesData;

            const cartDetailsPayload = { pickupStoresData: pickupStorePcs, estimatesResponse: cartResult || cart };
            const unavailableCartItemsList = await getCartUnavailableItems(cartDetailsPayload, true);

            // Code for sorting the stores list based on product availability and distance
            //  const sortedPickupStorePcs = getSortedStoreList(pickupStorePcs, unavailableCartItemsList);
            const formattedStores = formatStoreDataToObject(pickupStorePcs) || {};

            dispatch({
                type: SET_PICKUP_STORES,
                pickupStores: formattedStores
            });
            return { formattedStores, unavailableCartItemsList };
        }
        catch (error) {
            logError(error, false, 'fetchAndUpdatePickupStores');
        }
    };

    const normalizePcList = (pcList) => {
        if (Array.isArray(pcList)) {
            return pcList;
        } else if (typeof pcList === 'object' && pcList !== null) {
            return Object.values(pcList);
        } else {
            return [];
        }
    };

    const getCartandUnavailableItemsDetails = async (pickupStores) => {
        try {
            // function is used to fetch cart details and then calls getCartUnavailableItems with these details to retrieve the Map of unavailable items.
            const formattedPCList = normalizePcList(pickupStores)
            const cartDetailsPromise = cartId && isCartQuantityDiffFromStorage()
                ? getCartDetails({
                    cartDetailsQuery,
                    dispatch: cartDispatch,
                    cartId
                })
                : Promise.resolve(null);

            const cartResult = await cartDetailsPromise;

            const cartDetailsPayload = {
                pickupStoresData: formattedPCList,
                estimatesResponse: cartResult || cart  // Default to existing cart if no new details
            };
            const unavailableCartItemsMap = await getCartUnavailableItems(cartDetailsPayload, true);
            return unavailableCartItemsMap;
        } catch (error) {
            logError(error, false, 'getCartandUnavailableItemsDetails');
        }
    };

    const getSortedStoreList = (storeList, unavailableCartItems) => {
        // function is used to sort store cards by highest availability (All available) first, followed by stores with lower availability and distance.
        try {
            // Check if unavailableCartItems is empty
            if (isObjectEmpty(unavailableCartItems)) {
                return storeList;
            }
            return storeList
                ?.map(store => {
                    // Check the length of the unavailableCartItems array for each store
                    const unavailableStore = unavailableCartItems[store.pc] || [];
                    return {
                        ...store,
                        // Store availability and count of unavailable items
                        availability: unavailableStore?.length === 0 ? 1 : 0, // 1 if all available, 0 if unavailable
                        unavailableCount: unavailableStore?.length // Count of unavailable items
                    };
                })
                ?.sort((a, b) => {
                    // First, sort by availability (1 for all available first, then 0 for unavailable)
                    if (b?.availability !== a?.availability) {
                        return b?.availability - a?.availability; // All available stores come first
                    }

                    // Second, sort by the number of unavailable items (fewer unavailable items come first)
                    if (a?.unavailableCount !== b?.unavailableCount) {
                        return a?.unavailableCount - b?.unavailableCount; // Fewer unavailable items first
                    }

                    // Third, sort by distance (lowest first, closest store comes first)
                    return a?.distance - b?.distance;
                })
                ?.map(store => {
                    // Remove the 'availability' and 'unavailableCount' fields, returning only the original store object
                    const { availability, unavailableCount, ...rest } = store;
                    return rest;
                });
        }
        catch (error) {
            logError(error, false, 'getSortedStoreList');
        }
    };

    const updateUnavailableItemsOnDateChange = () => {
        if (!isCartQuantityDiffFromStorage() && storageCartQuantity > 0 && pickupStores) {
            getCartUnavailableItems({}, true);
        }
    };

    return {
        getJobsiteWithLatLong,
        distanceText,
        updateBsrPricingPCs,
        fetchLocalLocationCoordinates,
        showToggleButton,
        getCapUserDetails,
        getSelectedStoreLabel,
        getCartUpdatedDetails,
        getStoresData,
        isCapHeroComponent,
        isLocationPageHeroComponent,
        startRenting,
        isStoreDetailsUpdated,
        onEditViewSourceMatch,
        onRangePickerToggle,
        handleResetClick,
        isDatesChanged,
        startUpdateAndRatesLoading,
        getCapLocationLabel,
        updateBSRInStorageAndContext,
        getCartUnavailableItems,
        fetchAndUpdatePickupStores,
        getSortedStoreList,
        getCartandUnavailableItemsDetails,
        isCartQuantityDiffFromStorage,
        updateUnavailableItemsOnDateChange
    };
};
