import { useCartState } from '../../contexts/cart';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import { useFilterState } from '../../components/cap';
import useAnalyticsHelper from './useAnalyticsHelper';
import { usePageType } from '../usePageType';
import useUserData from '../useUserData';
import { useCheckAuthorityType, useCheckUser } from '../useCheckUser';
import { formatDate, isCheckoutPage } from '../../components/global/utils/commonUtils';
import { isValidString, logError } from '../../components/global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { AUTHORITY_TYPE, EMPLOYEE } from '../../components/global/constants';

const useAnalytics = () => {
    const userType = useCheckUser();
    const [{ userProfile }] = useUserContext();
    const [{ projectDetails, showUnavailableItems }] = useFilterState();
    const [{ userAccount, consumables }] = useCartState();
    const authorityType = useCheckAuthorityType();
    const [{ getProjectsFromCookie }] = useUserData();
    const {
        sendEventsForLocationAction,
        sendEventsForCtaChange,
        sendEventsForAvailabilityToggle,
        sendEventsForProductCard
    } = useAnalyticsContext();
    const [
        {
            bussinessSize,
            createListingItemObject,
            getAddressPayload,
            getEcommerceData,
            getFormattedConsumablesObj,
            getListItemDetails,
            getStartingInStatus
        }
    ] = useAnalyticsHelper();
    const pageType = usePageType();

    const payloadEcommerceActionAnalytics = (isConsumablesRequired = true, startDate = '', endDate = '') => {
        let cookieAccount = getProjectsFromCookie();
        const user = {
            account: cookieAccount?.CurrentWynneAccount || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: cookieAccount?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            address: getAddressPayload(cookieAccount, true)
        };
        const consumablesObj = { ...consumables?.selected };
        const formattedConsumablesObj =
            Object.keys(consumablesObj).length > 0
                ? getFormattedConsumablesObj(consumablesObj)
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        let ecommerce = getEcommerceData(startDate, endDate);
        if (isConsumablesRequired) {
            ecommerce = { ...ecommerce, consumables: formattedConsumablesObj };
        }
        return { user, ecommerce };
    };

    const payloadEcommercePageData = (categories, totalQuantity) => {
        const ecommerce = {
            currency:
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA,
            type: pageType,
            categories: categories,
            number_of_products: totalQuantity
        };
        return { ecommerce };
    };

    const payloadEcommerceLocationActionAnalytics = (error = false, userDataProvided) => {
        let address = {};
        let nearest_pc;
        let override_pc;

        if (error) {
            address = {
                line_1: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        } else {
            const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            address = getAddressPayload();
            nearest_pc = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
                ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))?.distance
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = overridePc?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        }
        const user = {
            id: userProfile?.userGuid,
            account_payment_type: authorityType === AUTHORITY_TYPE.P2P ? authorityType?.toLowerCase() : userType,
            authentication: userType !== USER_TYPE.GUEST ? true : false,
            account: userAccount?.accountNumber || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: projectDetails?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            location_set: address?.line_1 ? true : false,
            nearest_pc: nearest_pc,
            override_pc: override_pc,
            address: address
        };
        if (userDataProvided) {
            user.business_size = bussinessSize() || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            user.is_employee = userProfile?.Type === EMPLOYEE; // would always be false unless SBR employees can use this interface
            user.platform = authorityType === AUTHORITY_TYPE.P2P ? authorityType?.toLowerCase() : 'aem';
            if (authorityType === AUTHORITY_TYPE.P2P) {
                user.paperless = true; // set when checked in the user profile section of the my account area
                user.is_impersonating = false; // set when checked in the user profile section of the my account area
            }
        }
        return { user };
    };

    const payloadEcommerceLocationActionSubmitted = (localLocation, error = false) => {
        let address = {};
        let nearest_pc;
        let override_pc;
        if (error) {
            address = {
                line_1: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                line_2: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                city: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                state: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
                zipcode: VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY
            };
            nearest_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        } else {
            const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
            address = getAddressPayload({}, false, localLocation);
            nearest_pc = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)
                ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))?.distance
                : VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
            override_pc = overridePc?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY;
        }
        const user = {
            id: userProfile?.userGuid,
            account_payment_type: userType,
            authentication: userType !== USER_TYPE.GUEST ? true : false,
            account: userAccount?.accountNumber || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            project: localLocation?.projectName || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            location_set: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ))
                ? true
                : false,
            nearest_pc: nearest_pc,
            override_pc: override_pc,
            address: address
        };
        return { user };
    };

    const payloadEcommerceViewItemList = (products, isProduct = false, isSimilarItems = false, rates = [], dataset) => {
        const { currency, start_date, end_date, delivery_fee } = getEcommerceData();
        const payloadEcommerce = payloadEcommerceActionAnalytics(false);
        const address = payloadEcommerce?.user?.address || {};
        const locationPCObj = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)) || {};
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        const deliveryPc = locationPCObj?.pc;

        const ecommerceData = {
            currency,
            delivery_method: isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP))
                ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
                : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY,
            delivery_fee,
            delivery_pc: deliveryPc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            override_pc: overridePC?.pc || VARIABLE_CONFIG.ECOMMERCE.UNDEFINED_WITHOUT_STRINGIFY,
            start_date: formatDate(start_date),
            end_date: formatDate(end_date),
            starting_in: getStartingInStatus(),
            address,
            items: createListingItemObject(products, isProduct, rates, dataset)
        };

        if (!isProduct && !isSimilarItems && pageType !== VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE) {
            ecommerceData.availability_toggle = showUnavailableItems
                ? VARIABLE_CONFIG.AVAILABILITY_TOGGLE.HIDE
                : VARIABLE_CONFIG.AVAILABILITY_TOGGLE.SHOW;
        }

        return {
            ecommerce: ecommerceData
        };
    };

    const handleAnalyticsOnFocus = source => {
        try {
            if (
                source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY &&
                JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
                !isCheckoutPage()
            ) {
                sendEventsForLocationAction(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_ADDRESS,
                    payloadEcommerceLocationActionAnalytics()
                );
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
            }
        } catch (error) {
            logError(error, false, 'handleAnalyticsOnFocus');
        }
    };

    const sendAnalyticsForProductCardEvent = (product, event) => {
        try {
            const { listName, producttitle, producturl, catclass, position, index } = product || {};
            const { list_id, list_name } = getListItemDetails(listName);
            const type =
                event?.target?.tagName === 'IMG'
                    ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE_IMAGE
                    : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE;
            sendEventsForProductCard(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                producttitle?.trim(),
                type,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_LOCATION,
                producturl,
                catclass,
                list_id,
                list_name,
                position || index + 1
            );
        } catch (error) {
            logError(error, false, 'sendAnalyticsForProductCardEvent');
        }
    };

    const handleChangeCtaAnalytics = (linkName, linkDestination, sku, position, index, listName) => {
        try {
            const { list_id, list_name } = getListItemDetails(listName) || {};
            sendEventsForCtaChange(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
                linkName,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_LOCATION,
                linkDestination,
                sku,
                position || index + 1,
                { list_id, list_name }
            );
        } catch (error) {
            logError(error, false, 'handleChangeCtaAnalytics');
        }
    };

    const handleAvailabilityToggleEvents = checked => {
        try {
            sendEventsForAvailabilityToggle(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_TOGGLE_CHANGED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_TOGGLE_NAME,
                checked ? VARIABLE_CONFIG.AVAILABILITY_TOGGLE.HIDE : VARIABLE_CONFIG.AVAILABILITY_TOGGLE.SHOW
            );
        } catch (error) {
            logError(error, false, 'handleAvailabilityToggleEvents');
        }
    };

    return [
        {
            payloadEcommerceActionAnalytics,
            payloadEcommerceLocationActionAnalytics,
            payloadEcommerceLocationActionSubmitted,
            payloadEcommercePageData,
            payloadEcommerceViewItemList,
            handleAnalyticsOnFocus,
            handleChangeCtaAnalytics,
            handleAvailabilityToggleEvents,
            sendAnalyticsForProductCardEvent
        }
    ];
};

export default useAnalytics;
