import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, number, oneOf, oneOfType, shape, string } from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useCartState } from '../../../../contexts/cart';
import { useFilterState } from '../../../cap';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import useProductTile from '../../../../hooks/useProductTile';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import useCoachmark from '../../../../hooks/useCoachmark';
import Tooltip from '../../atoms/Tooltip';
import Available from './tileStates/Available';
import ChangeDatesGrt24Hrs from './tileStates/ChangeDatesGrt24Hrs';
import ChangeDatesGrt72Hrs from './tileStates/ChangeDatesGrt72Hrs';
import ChangeDatesOrStoreGrt24Hrs from './tileStates/ChangeDatesOrStoreGrt24Hrs';
import ChangeDatesOrStoreGrt72Hrs from './tileStates/ChangeDatesOrStoreGrt72Hrs';
import ChangeDatesAndStoreGrt24Hrs from './tileStates/ChangeDatesAndStoreGrt24Hrs';
import ChangeDatesAndStoreGrt72Hrs from './tileStates/ChangeDatesAndStoreGrt72Hrs';
import ChangeStore from './tileStates/ChangeStore';
import EmptyLocation from './tileStates/EmptyLocation';
import Unavailable from './tileStates/Unavailable';
import Loading from './tileStates/Loading';
import { TILE_STATES } from './constants';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { COACHMARK_TYPE } from '../../../cap/constants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const {
    AVAILABLE,
    CHANGE_STORE,
    CHANGE_DATES_GRT24HRS,
    CHANGE_DATES_GRT72HRS,
    CHANGE_DATES_OR_STORE_GRT72HRS,
    CHANGE_DATES_OR_STORE_GRT24HRS,
    EMPTY_LOCATION,
    UNAVAILABLE,
    EMPTY_LOCATION_AND_EMPTY_DATES,
    EMPTY_DATES,
    CHANGE_DATES_AND_STORE_GRT24HRS,
    CHANGE_DATES_AND_STORE_GRT72HRS,
    AVAILABLE_WITH_WARNING
} = TILE_STATES;

const ProductTile = ({
    tileState,
    tileDetails,
    handleClick,
    trackAnalytics,
    handleAddToCartClickAnalytics,
    isCoachmarkEnabled,
    ...restProps
}) => {
    const intl = useIntl();
    const [{ homeAssetsRates, userAccount, isRatesLoading }] = useCartState();
    const [{ isProfileLoaded }] = useUserContext();
    const [{ isCapDetailsUpdating }] = useFilterState();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();
    const { handleAddToCartClick, openEmptyLocationDateModal, fireEvent, showAvailableDates, showAvailableStores } =
        useProductTile();
    const [dailyProductRate, setDailyProductRate] = useState('');
    const [productTileCTARef, isProductTileCTAVisible] = useInView();
    const { setCoachmarkCount, handleProductTileCoachmark } = useCoachmark();
    const [showTileCoachmark, setShowTileCoachmark] = useState(false);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [tooltipMsg, setTooltipMsg] = useState('');
    const isTileLoading = !isProfileLoaded || isCapDetailsUpdating || (isRatesLoading && isRentalDetailsAvailable());
    const coachmarkTileClass = isTooltipOpen ? 'producttile__coachmark' : '';
    const { rentallabels = '{}' } = ENV_CONFIG.CAP_HEADER || {};
    const parsedRentalLabels = JSON.parse(rentallabels);
    const emptyRentalDetailsLabel =
        parsedRentalLabels?.['empty-rental-details'] || intl.formatMessage({ id: 'product-tile-rental-details' });
    const emptyRentalLocationLabel =
        parsedRentalLabels?.['empty-rental-location'] || intl.formatMessage({ id: 'product-tile-rental-location' });
    const emptyRentalDatesLabel =
        parsedRentalLabels?.['empty-rental-dates'] || intl.formatMessage({ id: 'product-tile-rental-dates' });

    useEffect(() => {
        return () => {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED);
        };
    }, []);

    useEffect(() => {
        if (isProfileLoaded && !isTileLoading && isCoachmarkEnabled) {
            handleProductTileCoachmark(tileDetails?.catclass, tileState, setShowTileCoachmark, setTooltipMsg);
        }
        return () => {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_PRODUCT_FOR_COACHMARK_SELECTED);
        };
    }, [isProfileLoaded, tileState, isTileLoading, isCoachmarkEnabled]);

    useEffect(() => {
        if (isProductTileCTAVisible && showTileCoachmark) {
            setCoachmarkCount(COACHMARK_TYPE.PRODUCT_TILE);
            setIsTooltipOpen(true);
        }
    }, [isProductTileCTAVisible, showTileCoachmark]);

    useEffect(() => {
        if (Object.keys(homeAssetsRates).length > 0) {
            setDailyProductRate(homeAssetsRates[tileDetails?.catclass]?.daily);
        } else {
            setDailyProductRate('');
        }
    }, [homeAssetsRates]);

    const restrictOnAnchorClick = async e => {
        e.preventDefault();
        e.stopPropagation();
        trackAnalytics && trackAnalytics(tileDetails, e);
        handleClick && (await handleClick());
        window.location = tileDetails?.producturl;
        return false;
    };

    const handleRedirect = async e => {
        fireEvent(tileDetails, dailyProductRate);
        await handleClick();
        window.location.href = tileDetails?.producturl;
    };

    const handleCloseCoachmark = () => {
        setShowTileCoachmark(false);
        setIsTooltipOpen(false);
    };

    const availableDatesData = type => {
        const { availableDate, selectDates } =
            showAvailableDates(
                type,
                false,
                tileDetails?.catclass,
                tileDetails?.position,
                tileDetails?.index,
                tileDetails?.listName
            ) || {};
        const handleSelectDate = () => {
            handleCloseCoachmark();
            selectDates?.();
        };
        return { availableDate, selectDates: handleSelectDate };
    };

    const availableStoresData = (isDateModalOpen, showAvailableStartDate) => {
        const { availableStores, selectStores } =
            showAvailableStores(tileDetails?.catclass, tileState, isDateModalOpen, showAvailableStartDate, tileDetails?.position, tileDetails?.index, tileDetails?.listName) || {};
        const handleSelectStore = (...args) => {
            handleCloseCoachmark();
            selectStores?.(...args);
        };
        return { availableStores, selectStores: handleSelectStore };
    };

    const renderProductTileState = () => {
        switch (tileState) {
            case EMPTY_LOCATION_AND_EMPTY_DATES:
                return (
                    <EmptyLocation
                        tileDetails={tileDetails}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        emptyDetailsText={emptyRentalDetailsLabel}
                        openEmptyLocationDateModal={openEmptyLocationDateModal}
                        accountNumber={userAccount?.accountNumber}
                        {...restProps}
                    />
                );
            case EMPTY_LOCATION:
                return (
                    <EmptyLocation
                        tileDetails={tileDetails}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        emptyDetailsText={emptyRentalLocationLabel}
                        openEmptyLocationDateModal={openEmptyLocationDateModal}
                        accountNumber={userAccount?.accountNumber}
                        {...restProps}
                    />
                );
            case EMPTY_DATES:
                return (
                    <EmptyLocation
                        tileDetails={tileDetails}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        emptyDetailsText={emptyRentalDatesLabel}
                        openEmptyLocationDateModal={openEmptyLocationDateModal}
                        accountNumber={userAccount?.accountNumber}
                        {...restProps}
                    />
                );
            case UNAVAILABLE:
                return (
                    <Unavailable
                        tileDetails={tileDetails}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        {...restProps}
                    />
                );
            case AVAILABLE_WITH_WARNING:
            case AVAILABLE:
                return (
                    <Available
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        handleAddToCartClick={handleAddToCartClick}
                        handleAddToCartClickAnalytics={handleAddToCartClickAnalytics}
                        isAvailableWithWarning={tileState === AVAILABLE_WITH_WARNING}
                        {...restProps}
                    />
                );
            case CHANGE_STORE:
                return (
                    <ChangeStore
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableStores={availableStoresData()}
                        footerTileClassName={coachmarkTileClass}
                        footerTileRef={productTileCTARef}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_GRT24HRS:
                return (
                    <ChangeDatesGrt24Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableDates={availableDatesData(CHANGE_DATES_OR_STORE_GRT24HRS)}
                        footerTileClassName={coachmarkTileClass}
                        footerTileRef={productTileCTARef}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_GRT72HRS:
                return (
                    <ChangeDatesGrt72Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableDates={availableDatesData(CHANGE_DATES_OR_STORE_GRT72HRS)}
                        footerTileClassName={coachmarkTileClass}
                        footerTileRef={productTileCTARef}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_OR_STORE_GRT24HRS:
                return (
                    <ChangeDatesOrStoreGrt24Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableDates={availableDatesData(CHANGE_DATES_OR_STORE_GRT24HRS)}
                        showAvailableStores={availableStoresData()}
                        footerTileClassName={coachmarkTileClass}
                        footerTileRef={productTileCTARef}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_OR_STORE_GRT72HRS:
                return (
                    <ChangeDatesOrStoreGrt72Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableDates={availableDatesData(CHANGE_DATES_OR_STORE_GRT72HRS)}
                        showAvailableStores={availableStoresData(false, false)}
                        footerTileClassName={coachmarkTileClass}
                        footerTileRef={productTileCTARef}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_AND_STORE_GRT24HRS:
                return (
                    <ChangeDatesAndStoreGrt24Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableStores={showAvailableStores(
                            tileDetails?.catclass,
                            CHANGE_DATES_OR_STORE_GRT24HRS,
                            true
                        )}
                        {...restProps}
                    />
                );
            case CHANGE_DATES_AND_STORE_GRT72HRS:
                return (
                    <ChangeDatesAndStoreGrt72Hrs
                        tileDetails={tileDetails}
                        dailyProductRate={dailyProductRate}
                        accountNumber={userAccount?.accountNumber}
                        handleAnchorClick={restrictOnAnchorClick}
                        handleRedirectClick={handleRedirect}
                        showAvailableStores={showAvailableStores(
                            tileDetails?.catclass,
                            CHANGE_DATES_OR_STORE_GRT72HRS,
                            true
                        )}
                        {...restProps}
                    />
                );
            default:
                null;
        }
    };

    const renderLoadingState = () => {
        return (
            <Loading
                tileDetails={tileDetails}
                dailyProductRate={dailyProductRate}
                accountNumber={userAccount?.accountNumber}
                handleAnchorClick={restrictOnAnchorClick}
                handleRedirectClick={handleRedirect}
                showAvailableDates={showAvailableDates(CHANGE_DATES_OR_STORE_GRT72HRS)}
                showAvailableStores={showAvailableStores(tileDetails?.catclass, CHANGE_DATES_OR_STORE_GRT72HRS)}
            />
        );
    };

    return (
        <>
            {isTileLoading ? renderLoadingState() : renderProductTileState()}
            {isTooltipOpen && (
                <Tooltip
                    isOpen
                    anchorSelect=".producttile__coachmark"
                    place="bottom-start"
                    handleTooltipClose={handleCloseCoachmark}>
                    {tooltipMsg}
                </Tooltip>
            )}
        </>
    );
};

export default React.memo(ProductTile);

ProductTile.defaultProps = {
    tileState: '',
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '#',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: '',
        productAltText: ''
    },
    handleClick: () => { },
    trackAnalytics: () => { },
    handleAddToCartClickAnalytics: () => { },
    isCoachmarkEnabled: true
};

ProductTile.propTypes = {
    tileState: oneOf([
        '',
        AVAILABLE,
        CHANGE_STORE,
        CHANGE_DATES_GRT24HRS,
        CHANGE_DATES_GRT72HRS,
        CHANGE_DATES_OR_STORE_GRT72HRS,
        CHANGE_DATES_OR_STORE_GRT24HRS,
        EMPTY_LOCATION,
        UNAVAILABLE,
        EMPTY_LOCATION_AND_EMPTY_DATES,
        EMPTY_DATES,
        CHANGE_DATES_AND_STORE_GRT24HRS,
        CHANGE_DATES_AND_STORE_GRT72HRS,
        AVAILABLE_WITH_WARNING
    ]),
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string,
        productAltText: string
    }),
    handleClick: func,
    trackAnalytics: func,
    handleAddToCartClickAnalytics: func,
    isCoachmarkEnabled: bool
};
