import React from 'react';
import { useIntl } from 'react-intl';
import { func, number, oneOfType, string } from 'prop-types';
import { useFilterState } from '../../../../../cap';
import { useCapUtils } from '../../../../../cap/hooks/useCapUtils';
import { useCartState } from '../../../../../../contexts/cart';
import Button from '../../../../atoms/button/button';
import { logError } from '../../../../utils/logger';
import ArrowRight from '../../../../../../resources/images/chevron-right-16.svg';


const StoreSelector = ({
    availableStores,
    selectStores,
    className,
    changeStoreRef,
    productName,
    pageType,
    btnCustomClass,
    changeStoreLabel
}) => {
    const intl = useIntl();
    const [{ pickupStores }] = useFilterState();
    const [{ cartId }] = useCartState();
    const { fetchAndUpdatePickupStores, isCartQuantityDiffFromStorage, getCartandUnavailableItemsDetails } = useCapUtils();

    const getStoresLabel = availableStores => {
        if (availableStores && parseInt(availableStores) > 1) {
            return `${availableStores} ${pageType === 'cart'
                ? intl.formatMessage({ id: 'cart:product-availability-stores-text-other' })
                : intl.formatMessage({ id: 'stores-text' })
                }`;
        } else {
            return `${availableStores} ${pageType === 'cart'
                ? intl.formatMessage({ id: 'cart:product-availability-store-text-other' })
                : intl.formatMessage({ id: 'store-text' })
                }`;
        }
    };

    const selectStoresHandler = async () => {
        try {
            if (!pickupStores) {
                // If no pickupStores are available, fetch and update the store list
                selectStores(null, true);
                const { formattedStores, unavailableCartItemsList } = await fetchAndUpdatePickupStores();
                selectStores(formattedStores, false, unavailableCartItemsList);
            } else {
                // If cartId exists and quantity is different from storage, fetch cart details
                if (cartId && isCartQuantityDiffFromStorage()) {
                    selectStores(null, true);
                    await getCartandUnavailableItemsDetails(pickupStores);
                }


                selectStores();
            }
        } catch (error) {
            logError(error, false, 'selectStoresHandler');
        }
    };

    const getChangeStoreLabel = () => {
        return changeStoreLabel || intl.formatMessage({ id: 'change-store' });
    };

    return (
        <>
            <Button
                btnRef={changeStoreRef}
                className={`button button-block producttile__storeselectore producttile__action ${className} ${btnCustomClass}`}
                onClick={selectStoresHandler}
                dataTestid="change_store_cta"
                buttonAriaLabel={`${intl.formatMessage({ id: 'product-availability-store-text' })} ${getStoresLabel(
                    availableStores
                )} ${intl.formatMessage({ id: 'change-store' })} for ${productName}`}>
                <span className="producttile__abailable">
                    {intl.formatMessage({
                        id: 'product-availability-store-text'
                    })}{' '}
                    <strong>{getStoresLabel(availableStores)}</strong>
                </span>

                <span className="producttile__changestore">
                    {getChangeStoreLabel()}
                    <ArrowRight />
                </span>
            </Button>
        </>
    );
};
export default React.memo(StoreSelector);

StoreSelector.defaultProps = {
    availableStores: '',
    selectStores: () => { },
    className: '',
    changeStoreRef: () => { },
    productName: '',
    changeStoreLabel: ''
};

StoreSelector.propTypes = {
    availableStores: oneOfType([number, string]),
    selectStores: func,
    className: string,
    changeStoreRef: func,
    productName: string,
    changeStoreLabel: string
};
