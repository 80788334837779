import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useCartState } from '../../../../contexts/cart';
import { useFilterState } from '../../../cap';
import { useCapUtils } from '../../../cap/hooks/useCapUtils';
import ItemAvailabilityAtStoreAlert from '../../../global/atoms/itemAvailabilityAtStoreAlert/ItemAvailabilityAtStoreAlert';
import Button from '../../../global/atoms/button';
import { filterStores } from '../../../checkoutv2/utils/editOrderDetailsFunctions';
import CheckedRadio from '../../../../../main/resources/images/checked.svg';
import UncheckedRadio from '../../../../../main/resources/images/unchecked.svg';
import classes from './storeCard.css';
import { closedStoreResponse } from './constants';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import { STORE_VARIANT } from '../../../cap/constants';
import { CLOSED } from '../../constants';

const StoreCard = props => {
    const intl = useIntl();
    const {
        item,
        selectedStoreValue,
        setSelectedStoreValue,
        onPressRadioButton,
        itemNumber,
        onStoreDetailClick,
        isStoresNearMe,
        nextAvailableStartDate,
        variant,
        showAvailableStartDate,
        companyId,
        showItemAvability,
        unavailableProducts
    } = props;
    let selectedItemId = item?.pc;

    const [{ howToGetYourOrderDetails }] = useCartState();
    const [{ startDate }] = useFilterState();
    const [date, setDate] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [dateSuffix, setDateSuffix] = useState('');
    const [nextAvailableDate, setNextAvailableDate] = useState('');
    const [nextDate, setNextDate] = useState('');
    const [nextMonth, setNextMonth] = useState('');
    const { distanceText } = useCapUtils();

    useEffect(() => {
        setDate(new Date(nextAvailableStartDate)?.getDate());
        setMonth(new Date(nextAvailableStartDate)?.toLocaleString('default', { month: 'short' }));
        setDay(new Date(nextAvailableStartDate)?.getDay());
        setNextAvailableDate(new Date(nextAvailableStartDate));
        setDateSuffix(moment(nextAvailableStartDate)?.format('Do')?.slice(-2) || '');
    }, [nextAvailableStartDate, showAvailableStartDate]);

    useEffect(() => {
        getNextDayandMonth(nextAvailableStartDate);
    }, [isStoresNearMe]);

    const getNextDayandMonth = date => {
        const dow = moment(date).day();
        let addDays = 4;
        let nextAvailDate = new Date(date);
        if (dow === 3) {
            addDays = 5; //Add 5 days if selected start day is wednesday
        }
        if (dow === 2) {
            addDays = 6; //Add 6 days if selected start day is tuesday
        }
        if (isStoresNearMe && notInBestMatch()) {
            setNextAvailableDate(nextAvailDate.setDate(nextAvailDate.getDate() + addDays));
        } else {
            // to find next availabile date
            while (operatingHoursData[nextDay]?.hours === closedStoreResponse && count < 7) {
                // if store is closed on all 7 days terminating while loop
                count++;
                if (nextDay === 6) {
                    nextDay = 0;
                } else {
                    nextDay++;
                }
            }
            setNextAvailableDate(nextAvailDate.setDate(nextAvailDate.getDate() + count));
        }
        setNextDate(nextAvailDate?.getDate());
        setNextMonth(nextAvailDate?.toLocaleString('default', { month: 'short' }));
    };
    const operatingHoursData = item?.operatingHours?.sort((day1, day2) => day1?.dayOfWeek - day2?.dayOfWeek); //To sort Data from Sunday to Saturday
    let nextDay = day;
    let count = 0;

    const notInBestMatch = () => {
        //To check if a store in closest stores list is in Best Match
        let flag = false;
        const currentDatePlusOne = moment().add(1, 'days');
        let localStartDate = moment(startDate);
        let filterStoreBasedOnDate = filterStores(howToGetYourOrderDetails?.bestMatchStoresData, startDate);
        filterStoreBasedOnDate?.map(data => {
            if (data?.pc === item?.pc) {
                if (!(data?.isLastResortBranch && localStartDate.isSameOrBefore(currentDatePlusOne))) {
                    flag = true;
                }
            }
        });
        if (flag) {
            return false;
        }
        return true;
    };
    // let nextAvailableDate = new Date(startDate);
    //T + N logic for Closest stores

    let phoneNo = item?.phone;
    if (item?.phone) {
        phoneNo = phoneNo.substr(1, 3) + '-' + phoneNo.substr(6, 8);
    }
    const getAriaLabel = (selectedStoreValue, selectedItemId, itemName) => {
        if (selectedStoreValue === selectedItemId) {
            return `${itemName} ${intl.formatMessage({ id: 'checkout:Stores-radio-button-checked' })}`;
        }
        return `${itemName} ${intl.formatMessage({ id: 'checkout:Stores-radio-button-unchecked' })}`;
    };
    const hrefphone = `tel:${phoneNo}`;
    const onPressHandler = item => {
        if (selectedStoreValue !== selectedItemId) {
            onPressRadioButton && onPressRadioButton(item);
            setSelectedStoreValue(selectedItemId);
        }
    };

    const isAvailable = () => {
        if (isStoresNearMe && notInBestMatch()) {
            return false;
        } else {
            return true;
        }
    };

    const checkforOpenStatus = () => {
        return (
            <div className={classes.dateTime}>
                <div className={classes.storeAvailabilty}>
                    <small
                        className={`${classes.openDate} ${classes.storeCardOpenStatus}`}
                        data-testid={checkoutDatalocator.checkout_store_openDate}>
                        {intl.formatMessage({ id: 'choose-store:open' })}
                    </small>
                </div>
                <div className={classes.hours} data-testid={checkoutDatalocator.checkout_store_openHours}>
                    {' '}
                    <strong>{operatingHoursData[day]?.hours || ''}</strong>{' '}
                    {intl.formatMessage({ id: 'choose-store:on' })} {month} {date}
                    {dateSuffix}
                </div>
            </div>
        );
    };

    const checkforClosedStatus = () => {
        return (
            <small className={`${classes.openDate} ${classes.storeCardOpenStatus}`} data-testid="closed">
                {CLOSED}
            </small>
        );
    };

    const displayDateAndTime = () => {
        return operatingHoursData[day]?.isOpen ? checkforOpenStatus() : checkforClosedStatus();
    };

    const displayAvailability = () => {
        return isAvailable() ? (
            <div className={classes.dateTime}>
                <div className={classes.storeAvailabilty}>
                    <small className={classes.openDate} data-testid={checkoutDatalocator.checkout_store_openDate}>
                        {intl.formatMessage({ id: 'choose-store:open' })} {month} {date}
                    </small>
                </div>
                <div className={classes.hours} data-testid={checkoutDatalocator.checkout_store_openHours}>
                    {' '}
                    {operatingHoursData[day]?.hours || ''}
                </div>
            </div>
        ) : (
            <div className={classes.dateTime}>
                <div className={classes.storeAvailabilty}>
                    <small
                        className={classes.closedDate}
                        data-testid={checkoutDatalocator.checkout_this_store_cannot_fulfill_the_selected_dates}>
                        {intl.formatMessage({ id: 'choose-store:unavailable' })}{' '}
                    </small>
                </div>
                <div
                    className={classes.hours}
                    data-testid={checkoutDatalocator.checkout_choose_from_availabe_dates_below_or_pick_another_store}>
                    {intl.formatMessage({ id: 'choose-store:items-available' })}
                    {nextMonth} {nextDate}
                </div>
            </div>
        );
    };

    const getCardTitle = () => {
        if (variant === STORE_VARIANT.CAP_HEADER) {
            return `${item?.city?.toLowerCase()} ${item?.state} - ${intl.formatMessage({
                id: 'store-details:branch'
            })}${item?.pc}`;
        } else {
            return `${item?.name}`;
        }
    };

    return (
        <>
            <div
                onClick={() => onPressHandler(item)}
                className={`${classes.storeCard} ${
                    selectedStoreValue === selectedItemId ? classes.cardRadioButtonSelected : ''
                }`}
                data-testid={checkoutDatalocator.checkout_closet_store_near_to_you_CTA}>
                {item?.pc === props.closestStoreId && (
                    <div
                        tabIndex={0}
                        className={classes.closestStore}
                        data-testid={checkoutDatalocator.checkout_closet_store_to_you_label}>
                        {intl.formatMessage({ id: 'checkout:closes-store-heading' })}
                    </div>
                )}
                <div
                    className={`${classes.storeCardBody} ${
                        selectedStoreValue === selectedItemId && classes.storeCardSelected
                    }`}>
                    <button
                        aria-label={getAriaLabel(selectedStoreValue, selectedItemId, getCardTitle())}
                        className={classes.storeCardIcon}
                        role="radio"
                        type="button"
                        aria-checked={selectedStoreValue === selectedItemId ? true : false}
                        tabIndex={0}>
                        {selectedStoreValue === selectedItemId ? (
                            <CheckedRadio aria-hidden="true" />
                        ) : (
                            <UncheckedRadio aria-hidden="true" />
                        )}
                    </button>
                    <div className={classes.storeCardDesc}>
                        <div
                            aria-label={`${itemNumber} ${item?.name} ${Number(
                                item?.distance ?? item?.drivingDistanceFromJobsite
                            ).toFixed(2)}${item?.distanceUnits}`}
                            className={`${classes.storeCardTitle} ${classes.mapStoreCardTitle}`}>
                            <span className="text-capitalise">
                                {itemNumber && `${itemNumber}.`} {getCardTitle()}
                            </span>
                            {variant == STORE_VARIANT.CAP_HEADER && (
                                <small
                                    data-testid={checkoutDatalocator.checkout_store_distance}
                                    className={classes.storeCardRight}>
                                    {Number(item?.distance ?? item?.drivingDistanceFromJobsite).toFixed(2)}{' '}
                                    {distanceText(companyId)}
                                </small>
                            )}
                        </div>
                        {showAvailableStartDate && (
                            <div className={classes.storeCardAvailableStarting}>
                                {intl.formatMessage({ id: 'available-starting' })} {month} {date}
                                {dateSuffix}
                            </div>
                        )}
                        {showItemAvability && <ItemAvailabilityAtStoreAlert unavailableItems={unavailableProducts} />}
                        <div className={classes.storeCardOpen} data-testid={checkoutDatalocator.closed_store_response}>
                            {variant == STORE_VARIANT.CAP_HEADER ? displayDateAndTime() : displayAvailability()}
                        </div>
                        <div
                            className={classes.storeCardAddress}
                            data-testid={checkoutDatalocator.checkout_InstorePickUp_Address_label_testid}>
                            {variant === STORE_VARIANT.CAP_HEADER && (
                                <small>
                                    {item?.specialties?.[0] ||
                                        intl.formatMessage({ id: 'location-card:general-equipText' })}
                                </small>
                            )}
                            <small>{`${item?.street},`}</small>
                            <small>{`${item?.city}, ${item?.state}, ${item?.zip}`}</small>
                        </div>
                        <div className={classes.storeCardActions}>
                            <Button
                                type="button"
                                onClick={event => {
                                    event.stopPropagation();
                                    onStoreDetailClick(item);
                                }}
                                className={`button button-pb0 ${classes.links}`}
                                buttonAriaLabel={intl.formatMessage({ id: 'choose-store:store-details' })}
                                data-testid={checkoutDatalocator.checkout_store_details_link}>
                                {intl.formatMessage({ id: 'choose-store:store-details' })}
                            </Button>
                            <a
                                title={`call us`}
                                href={hrefphone}
                                className={classes.links}
                                data-testid={checkoutDatalocator.checkout_store_phoneNumber}>
                                {phoneNo}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StoreCard;
