import React, { useEffect, useRef } from 'react';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENTER_KEY } from '../../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import useAnalytics from '../../../../hooks/analytics/useAnalytics';
import CheckedRadio from '../../../../resources/images/checked.svg';
import UncheckedRadio from '../../../../resources/images/unchecked.svg';
import { isCheckoutPage } from '../../utils/commonUtils';
import classes from './cardRadioButton.css';
import { AUTHORITY_TYPE } from '../../constants';

const CardRadioButton = props => {
    const {
        item,
        cardStyle,
        selectedValue,
        setSelectedValue,
        onPressRadioButton,
        isAccount,
        isProject,
        isAddress,
        radioButtonAriaLabel,
        isCard = false,
        index = '',
        isExpired = false,
        btnclasses,
        radioBtnDataLocator,
        source,
        customCardClass,
        isTransmit,
        cardStyleButtonWrapper,
        isDuplicateProjects,
        isSelectionRequired
    } = props;
    const radioRef = useRef(null);
    const authorityType = useCheckAuthorityType();
    const { sendEventsForLocationAction } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    let selectedItemId = '';
    if (isAccount) {
        selectedItemId = item?.account;
    } else if (isProject) {
        selectedItemId = item?.jobNumber;
    } else if (isAddress) {
        selectedItemId = item?.id;
    } else if (isCard) {
        selectedItemId = index;
    } else if (isTransmit) {
        selectedItemId = item.value;
    } else if (isDuplicateProjects) {
        selectedItemId = item?.projectId;
    } else {
        selectedItemId = item?.jobNumber;
    }
    // To Do - Arshdeep - commenting now as it is causing issue in account drawer
    // type in account drawer input box focus shift to selected account radio button
    // useEffect(() => {
    //     if(selectedValue === selectedItemId) {
    //         setTimeout(() => {
    //             radioRef.current.focus();
    //         }, 0);
    //     }
    // }, [selectedValue])

    const onPressHandler = () => {
        if (selectedValue !== selectedItemId) {
            onPressRadioButton && onPressRadioButton(item);
            setSelectedValue(selectedItemId);
        }
        if (
            source === VARIABLE_CONFIG.PLACEMENT.LOCATIONOVERLAY &&
            JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY)) &&
            !isCheckoutPage()
        ) {
            sendEventsForLocationAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_MODAL_FORM_NAME,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_NAME_PROJECT,
                payloadEcommerceLocationActionAnalytics()
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISLOCATIONFORMNOTDIRTY, false);
        }
    };
    let styleClass = `${classes.cardRadioButton}`;
    if (isCard) {
        styleClass = `${classes.cardRadioButtonPayment} ${customCardClass}`;
    }
    if (isExpired) {
        styleClass = `${styleClass}` + ` ${classes.expiredCard}`;
    } else if (selectedValue === selectedItemId) {
        styleClass = `${styleClass}` + ` ${classes.cardRadioButtonSelected}`;
    }

    const handleKeyPress = e => {
        if (e.key === ENTER_KEY) {
            onPressHandler();
        }
    };
    return (
        <div
            onClick={onPressHandler}
            onKeyDown={handleKeyPress}
            className={`${styleClass} ${cardStyleButtonWrapper} ${btnclasses ? classes.cardRadioMargin : ''} ${
                selectedValue === selectedItemId ? classes.cardRadioButtonSelected : ''
            }`}
            data-testid={radioBtnDataLocator || 'radioBtn'}>
            {!isExpired && (
                <div
                    className={classes.rootRadioButton}
                    ref={radioRef}
                    role="radio"
                    aria-checked={selectedValue === selectedItemId}
                    tabindex="0"
                    aria-label={radioButtonAriaLabel}>
                    {selectedValue === selectedItemId ? (
                        <CheckedRadio tabIndex={'-1'} aria-hidden={true} />
                    ) : (
                        <UncheckedRadio tabIndex={'-1'} aria-hidden={true} />
                    )}
                </div>
            )}
            {cardStyle(item)}
        </div>
    );
};

CardRadioButton.defaultProps = {
    item: {},
    radioButtonAriaLabel: '',
    cardStyle: '',
    selectedValue: '',
    setSelectedValue: () => {},
    btnclasses: '',
    radioBtnDataLocator: '',
    source: '',
    customCardClass: '',
    isTransmit: false,
    isAccount: false,
    isProject: false,
    isAddress: false,
    onPressRadioButton: () => {},
    isExpired: false,
    isCard: false,
    index: 0,
    cardStyleButtonWrapper: '',
    isSelectionRequired: true,
    isDuplicateProjects: false
};

CardRadioButton.propTypes = {
    item: object.isRequired,
    cardStyle: func,
    selectedValue: oneOfType([string, number]),
    setSelectedValue: func,
    onPressRadioButton: func,
    isAccount: bool,
    isProject: bool,
    isAddress: bool,
    radioButtonAriaLabel: string,
    isCard: bool,
    index: oneOfType([string, number]),
    isExpired: bool,
    btnclasses: string,
    radioBtnDataLocator: string,
    source: string,
    customCardClass: string,
    isTransmit: bool,
    cardStyleButtonWrapper: string,
    isSelectionRequired: bool,
    isDuplicateProjects: bool
};
export default React.memo(CardRadioButton);
